<template>
  <div class="password-rules-message">
    <img
      src="../assets/information.svg"
      alt=""
    >
    <div>
      La contraseña debe contener al menos 8 caracteres.
    </div>
  </div>
</template>

<script>
export default {
  name: 'PasswordRulesMessage'
}
</script>

<style lang="scss" scoped>
.password-rules-message {
  background: #e6f0ff;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  padding: 12px;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;

  img {
    margin-right: 16px;
  }
}
</style>
