<template>
  <div :class="containerClasses">
    <div v-if="invitationState.loading && !registrationState.success">
      <b-loading
        :is-full-page="false"
        :active="true"
        :can-cancel="false"
      />
    </div>
    <div
      v-if="!invitationState.loading || registrationState.success"
      class="form-box"
    >
      <transition-group :duration="500">
        <div
          v-if="registrationState.success"
          key="success"
          class="layer success-layer centered"
        >
          <div class="title">
            <h1>Te registraste con éxito</h1>
          </div>
          <router-link
            :to="{ name: 'proyectos' }"
            class="button is-success"
          >
            Ir a mi cuenta
          </router-link>
        </div>
        <div
          v-else-if="registrationState.invitationError || !invitationState.invitationValid"
          key="error"
          class="layer error-layer"
        >
          <div class="title">
            <h1>El enlace que utilizaste no es válido</h1>
          </div>
          <p class="spaced">
            Puede que ya lo hayas utilizado, o que haya caducado
          </p>
          <router-link
            :to="{ name: 'login' }"
            class="button is-success"
          >
            Volver
          </router-link>
        </div>
        <div
          v-else-if="registrationState.step == 1"
          key="step-1"
          ref="formLayer"
          class="layer form-container"
        >
          <div class="title">
            <h1>Registrate</h1>
          </div>
          <div class="step-counter">
            Paso 1 de 2
          </div>
          <!-- eslint-disable-next-line vue/no-v-html vue/max-attributes-per-line -->
          <p class="intro-text" v-html="introText" />
          <ValidationObserver ref="observer">
            <ValidationProvider
              v-slot="{ errors, invalid }"
              ref="firstName"
              rules="required"
            >
              <b-field
                label="Nombre"
                :type="{ 'is-danger': registrationState.step1Submitted && invalid }"
                :message="registrationState.step1Submitted ? errors : []"
              >
                <b-input
                  v-model="firstName"
                  type="text"
                  size="is-medium"
                  placeholder="Ingresá tu nombre"
                  :disabled="registrationState.loading"
                  @keyup.native.enter="completeRegistrationStep1"
                />
              </b-field>
            </ValidationProvider>
            <ValidationProvider
              v-slot="{ errors, invalid }"
              ref="lastName"
              rules="required"
            >
              <b-field
                label="Apellido"
                :type="{ 'is-danger': registrationState.step1Submitted && invalid }"
                :message="registrationState.step1Submitted ? errors : []"
              >
                <b-input
                  v-model="lastName"
                  type="text"
                  size="is-medium"
                  placeholder="Ingresá tu apellido"
                  :disabled="registrationState.loading"
                  @keyup.native.enter="completeRegistrationStep1"
                />
              </b-field>
            </ValidationProvider>
            <b-field label="Correo electrónico">
              <b-input
                type="email"
                size="is-medium"
                class="input-email input-email-predefined"
                disabled
                :value="invitationState.email"
              />
            </b-field>
            <p class="help-text">
              Tu correo electrónico ya fue verificado
            </p>
            <div v-if="invitationState.admin">
              <ValidationProvider
                v-slot="{ errors, invalid }"
                ref="company"
                rules="required"
              >
                <b-field
                  label="Empresa"
                  :type="{ 'is-danger': registrationState.step1Submitted && invalid }"
                  :message="registrationState.step1Submitted ? errors : []"
                >
                  <b-input
                    v-model="company"
                    type="text"
                    size="is-medium"
                    placeholder="Ingresá el nombre de tu empresa"
                    :disabled="registrationState.loading"
                    @keyup.native.enter="completeRegistrationStep1"
                  />
                </b-field>
              </ValidationProvider>
            </div>
            <ValidationProvider
              v-slot="{ errors, invalid }"
              ref="password"
              rules="required|min:8|password:@confirmation"
            >
              <b-field
                label="Ingresá tu nueva contraseña"
                :type="{ 'is-danger': registrationState.step1Submitted && invalid }"
                :message="registrationState.step1Submitted ? errors : []"
              >
                <b-input
                  v-model="password"
                  type="password"
                  size="is-medium"
                  icon="eye"
                  class="input-password"
                  placeholder="Ingresá tu contraseña"
                  validation-message="Ingresa una Contraseña válida."
                  required
                  password-reveal
                  :disabled="registrationState.loading"
                  @keyup.native.enter="completeRegistrationStep1"
                />
              </b-field>
            </ValidationProvider>
            <ValidationProvider
              v-slot="{ errors, invalid }"
              ref="confirmation"
              rules="required|min:8"
              name="confirmation"
            >
              <b-field
                label="Confirmá tu contraseña"
                :type="{ 'is-danger': registrationState.step1Submitted && invalid }"
                :message="registrationState.step1Submitted ? errors : []"
              >
                <b-input
                  v-model="confirmation"
                  type="password"
                  size="is-medium"
                  icon="eye"
                  class="input-password"
                  placeholder="Ingresá tu contraseña"
                  validation-message="Ingresa una Contraseña válida."
                  required
                  password-reveal
                  :disabled="registrationState.loading"
                  @keyup.native.enter="completeRegistrationStep1"
                />
              </b-field>
            </ValidationProvider>
          </ValidationObserver>
          <password-rules-message />
          <div class="field">
            <p class="control">
              <button
                type="button"
                class="button is-success"
                :class="{ 'loading': registrationState.loading, 'success': registrationState.success }"
                :loading="registrationState.loading"
                @click="completeRegistrationStep1"
              >
                Siguiente
              </button>
            </p>
          </div>
          <div class="field">
            <p class="control">
              <router-link
                :to="{ name: 'login' }"
                class="button is-outlined"
              >
                Volver
              </router-link>
            </p>
          </div>
        </div>
        <div
          v-else-if="registrationState.step == 2"
          key="step-2"
          class="layer"
        >
          <div class="title">
            <h1>Registrate</h1>
          </div>
          <div class="step-counter">
            Paso 2 de 2
          </div>
          <!-- eslint-disable-next-line vue/no-v-html vue/max-attributes-per-line -->
          <p class="intro-text" v-html="introText" />
          <platform-terms-and-conditions :registration="true" />
          <div
            class="field terms-confirmation"
          >
            <b-checkbox
              v-model="termsAccepted"
              size="is-medium"
            >
              He leído y acepto los Términos y Condiciones de la plataforma.
            </b-checkbox>
          </div>
          <b-notification
            v-if="registrationState.step2Submitted && !termsAccepted"
            type="is-danger"
            role="alert"
            :closable="false"
          >
            Para continuar debes aceptar los Términos y Condiciones
          </b-notification>
          <b-notification
            v-if="registrationState.networkError"
            type="is-danger"
            role="alert"
            aria-close-label="Ocultar"
          >
            Ha ocurrido un error, por favor intenta nuevamente.
          </b-notification>
          <form
            ref="confirmationForm"
            class="form"
            novalidate
            @submit.prevent="submitForm"
          >
            <input
              type="email"
              :value="invitationState.email"
              class="hidden"
            >
            <input
              type="password"
              :value="password"
              class="hidden"
            >
            <button
              type="button"
              class="button is-success"
              :class="{ 'loading': registrationState.loading, 'success': registrationState.success }"
              :loading="registrationState.loading"
              @click="completeRegistrationStep2"
            >
              Completar registro
            </button>
            <button
              ref="submitButton"
              type="submit"
              class="hidden"
            />
          </form>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script>
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'
import { min, required } from 'vee-validate/dist/rules'

import PasswordRulesMessage from './PasswordRulesMessage.vue'
import PlatformTermsAndConditions from './PlatformTermsAndConditions.vue'

extend('min', {
  ...min,
  message: 'La contraseña debe tener al menos 8 caracteres'
})

extend('required', {
  ...required,
  message: 'Este campo es obligatorio'
})

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target
  },
  message: 'La contraseña y confirmación no coinciden'
})

export default {
  name: 'RegistrationForm',
  components: {
    PasswordRulesMessage,
    PlatformTermsAndConditions,
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      company: '',
      password: '',
      confirmation: '',
      termsAccepted: false
    }
  },
  computed: {
    invitationState() {
      return this.$store.state.Invitations
    },
    registrationState() {
      return this.$store.state.Registration
    },
    invitationLoading() {
      return true
    },
    introText() {
      return (
        this.invitationState.admin
          ? 'Registrate en Relevant para administrar el proyecto '
        + `<span class="project-name">${this.$store.state.Invitations.projectName}</span>.`
          : 'Registrate en Relevant para participar del proyecto '
        + `<span class="project-name">${this.$store.state.Invitations.projectName}</span>.`
      )
    },
    containerClasses() {
      return [
        'form-container', 'simple-form', 'animated', 'small-padding',
        this.registrationState.loading ? 'loading' : '',
        this.registrationState.networkError ? 'error' : '',
        this.registrationState.success ? 'success' : ''
      ]
    }
  },
  beforeMount() {
    document.getElementsByTagName('html')[0].classList.add('no-vertical-scrollbar')
  },
  destroyed() {
    this.$store.commit('Registration/resetState')
    delete document.getElementsByTagName('html')[0].classList.remove('no-vertical-scrollbar')
  },
  methods: {
    scrollToError(selector) {
      // Scrolls the form layer to the first field that contains errors
      this.$nextTick(() => {
        const targetElementTop = (
          Math.round(this.$el.querySelectorAll(selector)[0].getBoundingClientRect().top)
          + this.$refs.formLayer.scrollTop - (window.innerWidth < 1024 ? 140 : 60)
        )
        this.$refs.formLayer.scrollTop = targetElementTop
      })
    },
    async completeRegistrationStep1() {
      if (this.registrationState.loading) return

      const validateResult = await this.$refs.observer.validate()

      if (!validateResult) {
        this.$store.commit('Registration/step1Submitted')
        this.scrollToError('input.is-danger')
        return
      }

      // Exceptions are handled internally in the store and reflect automatically on the UI
      this.$store.dispatch('Registration/completeRegistrationStep1')
    },
    completeRegistrationStep2() {
      if (!this.termsAccepted) {
        this.$store.commit('Registration/step2Submitted')
        return
      }
      this.$refs.submitButton.click()
    },
    submitForm() {
      this.$store.dispatch('Registration/completeRegistrationStep2', {
        invitationCode: this.invitationState.invitationCode,
        firstName: this.firstName,
        lastName: this.lastName,
        company: this.company,
        password: this.password
      })
    }
  }
}
</script>

<style lang='scss' scoped>
  @import '../scss/simple_form.scss';

  .form-container {
    padding-bottom: 50px;
  }

  .simple-form .form-box {
    .field.terms-confirmation {
      margin-bottom: 33px;

      ::v-deep .b-checkbox.checkbox {
        padding: 0;
        align-items: flex-start;

        input.checkbox, span.check {
          position: relative;
          top: 4px;
        }
      }
    }
  }

  .form > .field:last-child {
    margin-bottom: 0;
  }

  ::v-deep .intro-text {
    margin-bottom: 28px;

    .project-name {
      font-weight: 700;
    }
  }

  @media (max-width: 339.98px) {
    .simple-form .form-box .title h1 {
      font-size: 25px;
    }
  }
</style>
